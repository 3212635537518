.ModalWrapper {
    align-content: center;
}

.NounImg {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    width: 100%;
    max-width: 250px;
    border-radius: 15px;
}

.Footer {
    margin-top: 6px;
    margin-bottom: 14px;
    font-size: 18px;
}

h3 {
    font-size: x-large;
    font-weight: bold;
    /*margin-top: 8px;*/
}

.twitterBtn {
  /*font-family: 'PT Root UI';*/
  border-radius: 12px !important;
  margin-left: 0.6rem !important;
  margin-top: 3px;
  width: auto;
  padding: 10px 16px;
  height: 3rem;
  border: transparent;
  font-weight: bolder;
  letter-spacing: normal;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  
  background: #1d9bf0;
  color: white;
  min-width: 200px;
  text-decoration: none;
}

.twitterBtn:hover,
.twitterBtn:active,
.twitterBtn:focus .twitterBtn:disabled {
  outline: none !important;
  box-shadow: none !important;
  /*background-color: #2125298a;*/
  background: #0c7abf;
  color: white;
}