.Documentation {
  background-color: black;
  padding-top: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

a {
  color: #F20422;
  text-decoration: none;
  font-weight: bold;
}

.headerWrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 4rem;
  color: #ea3628;  
}
.headerWrapper p {
  margin: 3rem 0;
}

.fiveHousesLogos {
  max-width: 100%;
}


@media (max-width: 992px) {
  .headerWrapper {
    padding: 1rem;
  }
}

.accordionItem {
  margin-bottom: 2.5rem;
  border: none;
  background: black;
  color: white;
}

.accordionItem,
.headerWrapper {
  font-size: 1.3rem;
}

.accordionHeader button:hover {
  color: var(--brand-dark-red) !important;
}

.accordionHeader button {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem !important;
  cursor: pointer;
  line-height: normal;
  padding-top: 0;
  padding-bottom: 0;
}
.accordionHeader button:not(.collapsed) {
  /*color: #212529;*/
  color: #ea3628;
  background-color: transparent;
  box-shadow: none !important;
}
/* override bootstrap color */
.accordionHeader button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ea3628'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordionHeader button:focus {
  color: var(--brand-dark-red) !important;
  box-shadow: none !important;
}

h1,
h2 {
  font-family: 'Londrina Solid';
  color: #F20422;
}

h2 {
  margin-bottom: 2rem;
}

.siteGroup {
  padding-right: 5px;
  padding-left: 5px;
}
.siteGroup a {
  font-size: 1rem;
}

.marginBottom {
  margin-bottom: 0rem;
}

.siteGroup {
  margin-bottom: 2rem;
  text-align: center;
}

.siteGroup img {
  margin-bottom: 1rem;

  border-radius: 50%;
  width: 50%;
}

.twitterIcon {
  width: 20px;
  margin-right: 5px;
}

.card {
  border: none;
}

.card,
.headerWrapper {
  font-size: 1.3rem;
}
.cardHeader {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}