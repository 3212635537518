.Footer{
    text-align: center;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-self: center;
    background: black;
    width: 100%;
    color: white;
}

.twitterIcon {
  width: 20px;
  margin-right: 5px;
}
